<template>
    <vx-card title="Procesos por resultados" id="card">
      <VueApexCharts type="donut" height="350" :options="chartOptions" :series="series" v-if="renderGraphic"/>
    </vx-card>
</template>

<script>
import { eventBus } from './../../main'
import VueApexCharts from 'vue-apexcharts';
import api from '../../mixins/api'
import { mapGetters } from 'vuex'
export default {
  props: ['startDate', 'endDate', 'cityId'],
  mixins:[api],
  data(){
    return{
      renderGraphic: false,
      series: [44, 55, 41],
      chartOptions:{
        labels: ['Confiables', 'Confiables con observaciones', 'No confiables'],
        colors:['#5F0091', '#ffc107', '#dc3545'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(2) + "%"
          }
        }
      }
    }
  },
  components: {
    VueApexCharts,
  },
  methods:{
    getUrl(){
      let path = `/metrics/projects-by-results?client_id=${this.userInfo.user.client_id}`
      let url = ''
      if (this.startDate != null && this.endDate != null) {
        let params = `&start_date=${this.startDate}&end_date=${this.endDate}`
        url = `${this.url()}${path}${params}`
      } else {
        url = `${this.url()}${path}`
      }

      if(this.cityId != null) {
        url += '&city_id='+this.cityId
      }
      return url
    },
    async initializeData() {
      const request = await this.requestGetWithoutContent(this.getUrl(), {})
      if (request.status == 200) {
        let data = request.data
        await this.updateSeries(data)
      }
    },
    updateSeries(data){
      
      this.series = []
      this.$set(this.series,0,data[0].trustprojects)
      this.$set(this.series,1,data[0].unreliableprojects)
      this.$set(this.series,2,data[0].untrusprojects)
      this.renderGraphic = true
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted(){
    this.initializeData()
    eventBus.$on('consultData', () => {
      this.initializeData()
    })
  }
}
</script>

<style>

</style>