<template>
  <div id="candidate-years-experience">
    <vx-card title="Candidatos por estado civil y genero">
      <VueApexCharts type="bar" height="350" :options="chartOptions" :series="series" v-if="renderGraphic"/>
    </vx-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import api from '../../mixins/api'
import { mapGetters } from 'vuex'
import { eventBus } from './../../main'
export default {
  props: ['startDate', 'endDate', 'cityId'],
  mixins: [api],
  data() {
    return {
      renderGraphic: false,
      series: [{}, {}],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '70%',
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        colors: ['#5F0091', '#FFCF44'],
        xaxis: {
          categories: ['Soltero', 'Casado', 'Viudo', 'unión libre'],
        },
        yaxis: {
          title: {
            text: 'Miles'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " candidatos"
            }
          }
        }
      },
    }
  },
  methods: {
    getUrl(){
      let path = `metrics/candidate-civil-state-gender?client_id=${this.userInfo.user.client_id}`
      let url = ''
      if (this.startDate != null && this.endDate != null) {
        let params = `&start_date=${this.startDate}&end_date=${this.endDate}`
        url = `${this.url()}${path}${params}`
      } else {
        url = `${this.url()}${path}`
      }

      if(this.cityId != null) {
        url += `&city_id=${this.cityId}`
      }
      return url
    },
    async initializeData() {
      const request = await this.requestGetWithoutContent(this.getUrl(), {})
      if (request.status == 200) {
        let data = request.data
        await this.updateSeries(data)
      }
    },
    async updateSeries(data) {
      for (let index = 0; index < 2; index++) {
        this.$set(this.series, index, { name: data.ranges[index], data: data.series[index] })
      }
      this.renderGraphic = true
    },
  },
  mounted () {
    this.initializeData()
    eventBus.$on('consultData', () => {
      this.initializeData()
    })
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  components: {
    VueApexCharts,
  }
}
</script>
<style scoped>
  .form-graphic{
    margin-top: 1rem;
  }
</style>
