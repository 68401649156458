<template>
  <div id="candidate-years-experience">
    <vx-card title="Candidatos por años de experiencia">
      <apexcharts type="bar" height="350" :options="chartOptions" :series="series" v-if="renderGraphic"/>
    </vx-card>
  </div>
</template>

<script>
import { eventBus } from './../../main'
import VueApexCharts from 'vue-apexcharts';
import api from '../../mixins/api'
import { mapGetters } from 'vuex'
export default {
  props: ['startDate', 'endDate'],
  mixins: [api],
  data() {
    return {
      renderGraphic: false,
      series: [
        {
          name: 'Candidatos por años de experiencia',
          data: []
        }
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '50%'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Candidatos'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${val} candidatos`
            }
          }
        }
      },
    }
  },
  methods: {
    getUrl(){
      let path = `metrics/candidate-years-experience?client_id=${this.userInfo.user.client_id}`
      let url = ''
      if (this.startDate != null && this.endDate != null) {
        let params = `&start_date=${this.startDate}&end_date=${this.endDate}`
        url = `${this.url()}${path}${params}`
      } else {
        url = `${this.url()}${path}`
      }
      return url
    },
    async initializeData() {
      const request = await this.requestGetWithoutContent(this.getUrl(), {})
      if (request.status == 200) {
        let data = request.data
        await this.updateXaxis(data)
        await this.updateSeries(data)
      }
    },
    async updateXaxis(data) {
      let categories = []
      data.forEach(element => categories.push(Object.keys(element)[0]))
      this.$set(this.chartOptions, 'xaxis', { categories: categories })
    },
    async updateSeries(data) {
      let series = []
      data.forEach(element => series.push(element[Object.keys(element)[0]]))
      this.$set(this.series, 0, { name: 'Candidatos por años de experiencia', data: series })
      this.renderGraphic = true
    },
  },
  mounted () {
    this.initializeData()
    eventBus.$on('consultData', () => {
      this.initializeData()
    })
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  components: {
    'apexcharts':VueApexCharts,
  }
}
</script>
<style scoped>
  .form-graphic{
    margin-top: 1rem;
  }
</style>
