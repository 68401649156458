var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "banner" }, [
    _c(
      "p",
      {
        staticStyle: {
          "padding-top": "92px",
          "font-size": "34px",
          "padding-left": "40px",
        },
      },
      [_vm._v(_vm._s(_vm.client_name))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }