<template>
  <vx-card title="Procesos por tipo" id="card">
    <VueApexCharts type="donut" height="350" :options="chartOptions" :series="series" v-if="renderGraphic"/>
  </vx-card>
</template>

<script>
import { eventBus } from './../../main'
import VueApexCharts from 'vue-apexcharts';
import api from '../../mixins/api'
import { mapGetters } from 'vuex'
export default {
  props: ['startDate', 'endDate', 'cityId'],
  mixins:[api],
  data(){
    return{
      renderGraphic: false,
      series: [],
      chartOptions:{
        labels: [],         
        colors:['#ffc300', '#5F0091', '#c88170','#c5c870','#5eab2c','#ff3200','#0d2cc8','#c20dc8','#bf0093',
        '#616a6b','#0e6251','#0e6251','#d35400','#17202a','#f5cba7'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(2) + "%"
          }
        }
      }
    }
  },
  components: {
    VueApexCharts,
  },
  methods:{
    getUrl(){
      let path = `/metrics//projects-by-type?client_id=${this.userInfo.user.client_id}`
      let url = ''
      if (this.startDate != null && this.endDate != null) {
        let params = `&start_date=${this.startDate}&end_date=${this.endDate}`
        url = `${this.url()}${path}${params}`
      } else {
        url = `${this.url()}${path}`
      }

      if(this.cityId != null) {
        url += '&city_id='+this.cityId
      }
      return url
    },
    async initializeData() {
      const request = await this.requestGetWithoutContent(this.getUrl(), {})
      if (request.status == 200) {
        let data = request.data
        await this.updateLabels(data)
        await this.updateSeries(data)
      }
    },
    updateSeries(data){
      console.log('La data type', data)
      this.series = []
      data.forEach((element,index) => {this.$set(this.series,index,element.count)})
      this.renderGraphic = true
    },
    async updateLabels(data) {
      data.forEach((element,index) => {this.$set(this.chartOptions.labels,index,this.$t(element.name))})
    }
  },
  mounted(){
    this.initializeData()
    eventBus.$on('consultData', () => {
      this.initializeData()
    })
  },
  computed:{
    ...mapGetters(['userInfo'])
  }
}
</script>

<style>

</style>