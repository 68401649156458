var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    { attrs: { title: "Procesos por cargo", id: "card" } },
    [
      _vm.renderGraphic
        ? _c("vue-apex-charts", {
            ref: "projectsByCharges",
            attrs: {
              type: "bar",
              height: "1000",
              options: _vm.chartOptions,
              series: _vm.series,
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "center" } },
        [
          _c(
            "button",
            {
              staticStyle: {
                background: "transparent",
                border: "none",
                cursor: "pointer",
              },
              on: { click: _vm.prevPage },
            },
            [
              _c("span", { staticClass: "material-icons" }, [
                _vm._v("arrow_back_ios"),
              ]),
            ]
          ),
          _vm._v(
            "\n      " +
              _vm._s(this.position + 1) +
              "/" +
              _vm._s(_vm.pages.length) +
              "\n    "
          ),
          _c(
            "button",
            {
              staticStyle: {
                background: "transparent",
                border: "none",
                cursor: "pointer",
              },
              on: { click: _vm.nextPage },
            },
            [
              _c("span", { staticClass: "material-icons" }, [
                _vm._v("arrow_forward_ios"),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }