<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <!-- <div class="vx-col w-full lg:w-1/1 mb-base">
        <bi-chart/>
      </div> -->
      <div class="vx-col w-full lg:w-1/1 mb-base" v-if="startDate != null && endDate != null">
        <vx-card title="" id="tabs-gráficas">
          <div id="bannerContainer" style="margin-bottom: 2rem;">
            <banner-clients/>
          </div>

          <div class="vx-row" v-if="this.userInfo.role_name != 'Cliente'">
            <div class="vx-col w-full lg:w-1/2 mb-base">
              <pie-chart/>
            </div>
            <div class="vx-col w-full lg:w-1/2 mb-base">
              <column-chart/>
            </div>
          </div>

          <div class="vx-col w-full lg:w-1/2 mb-base">
            Total: {{totalCount}} procesos
            <div class="vx-row">
              <div class="vx-col sm:w-1/4 w-full">
                Fecha de inicio:
                <datepicker mode="date" :language="languages[language]" v-validate="'required'" class="pickadate-limits" name="dateStart" v-model="startDateMask"></datepicker>
                <span class="text-danger text-sm" v-show="errors.has('dateStart')">Requerido</span>
              </div>
              <div class="vx-col sm:w-1/4 w-full">
                Fecha final:
                <datepicker mode="date" :language="languages[language]" v-validate="'required'" class="pickadate-limits" name="dateEnd" v-model="endDateMask"></datepicker>
                <span class="text-danger text-sm" v-show="errors.has('dateEnd')">Requerido</span>
              </div>
                <vs-select id="selectCountry" :autocomplete="true" v-model="country_id" class="vx-col sm:w-1/4 w-full" label="País" name="country_id" v-validate="'required'">
                  <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in countries" class="w-full" />
                </vs-select>
                <vs-select id="selectCity" :autocomplete="true" v-model="city_id" class="vx-col sm:w-1/4 w-full" label="Ciudad" name="city_id" v-validate="'required'">
                  <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in cities" class="w-full" />
                </vs-select>
              <div class="vx-col sm:w-1/4 w-full">
                <br>
                <vs-button type="filled" @click.prevent="consultData" class="block">Consultar</vs-button>
              </div>
            </div>
          </div>
          <vs-tabs>
            <vs-tab label="Procesos">
              <div class="con-tab-ejemplo">
                <div class="vx-row">
                  <div class="vx-col w-full lg:w-1/2 mb-base">
                    <projects-by-advance :startDate="startDate" :endDate="endDate" :cityId="city_id"/>
                  </div>
                  <div class="vx-col w-full lg:w-1/2 mb-base">
                    <qualification-average :startDate="startDate" :endDate="endDate" :cityId="city_id"/>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col w-full lg:w-1/2 mb-base">
                    <projects-by-results :startDate="startDate" :endDate="endDate" :cityId="city_id"/>
                  </div>
                  <div class="vx-col w-full lg:w-1/2 mb-base">
                    <projects-by-type :startDate="startDate" :endDate="endDate" :cityId="city_id"/>
                  </div>
                  <div class="vx-col w-full lg:w-1/2 mb-base">
                    <untrust-projects :startDate="startDate" :endDate="endDate" :cityId="city_id"/>
                  </div>
                  <div class="vx-col w-full lg:w-1/2 mb-base">
                    <unreliable-projects :startDate="startDate" :endDate="endDate" :cityId="city_id"/>
                  </div>
                </div>
              </div>
            </vs-tab>
            <vs-tab label="Candidatos">
              <div class="con-tab-ejemplo">
                <div class="vx-row">
                  <div class="vx-col lg:w-1/2 mb-base">
                    <candidate-gender-age :startDate="startDate" :endDate="endDate" :cityId="city_id"/>
                  </div>
                  <div class="vx-col lg:w-1/2 mb-base">
                    <candidate-civil-state-gender :startDate="startDate" :endDate="endDate" :cityId="city_id"/>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col w-full lg:w-1/2 mb-base">
                    <candidate-age-result :startDate="startDate" :endDate="endDate" :cityId="city_id"/>
                  </div>
                  <div class="vx-col w-full lg:w-1/2 mb-base">
                    <candidate-age-civil-state :startDate="startDate" :endDate="endDate" :cityId="city_id"/>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col w-full lg:w-1/2 mb-base">
                    <candidate-civil-state-result :startDate="startDate" :endDate="endDate" :cityId="city_id"/>
                  </div>
                  <div class="vx-col w-full lg:w-1/2 mb-base">
                    <candidate-age-study :startDate="startDate" :endDate="endDate" :cityId="city_id"/>
                  </div>
                </div>
                <!-- <div class="vx-col w-full lg:w-1/1 mb-base">
                  <candidate-years-experience :startDate="startDate" :endDate="endDate"/>
                </div> -->
              </div>
            </vs-tab>
          </vs-tabs>
        </vx-card>
      </div>
      <div v-else>
        No existe información
      </div>
    </div>
  </div>
</template>

<script>
import api from '../mixins/api'
import cities from '../mixins/cities'
import { eventBus } from './../main'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import * as lang from 'vuejs-datepicker/src/locale';
import BiChart from './../components-globalwork/BiChart.vue'
import CandidateYearsExperience from './../components-globalwork/metrics/candidate_years_experience.vue'
import CandidateAgeStudy from './../components-globalwork/metrics/candidate_age_study.vue'
import CandidateCivilStateResult from './../components-globalwork/metrics/candidate_civil_state_result.vue'
import CandidateAgeCivilState from './../components-globalwork/metrics/candidate_age_civil_state.vue'
import CandidateAgeResult from './../components-globalwork/metrics/candidate_age_result.vue'
import CandidateCivilStateGender from './../components-globalwork/metrics/candidate_civil_state_gender.vue'
import CandidateGenderAge from './../components-globalwork/metrics/candidate_gender_age.vue'
import ProjectsByAdvance from './../components-globalwork/metrics/projects_by_advance'
import QualificationAverage from './../components-globalwork/metrics/qualification_average_by_project_type.vue'
import ProjectsByResults from './../components-globalwork/metrics/projects_by_results.vue'
import ProjectsByType from './../components-globalwork/metrics/projects_by_type.vue'
import ProjectsByCharge from './../components-globalwork/metrics/projects_by_charge.vue'
import BannerClients from './../components-globalwork/metrics/banner_clients.vue'
import UntrustProjects from './../components-globalwork/metrics/untrust_projects.vue'
import UnreliableProjects from './../components-globalwork/metrics/unreliable_projects.vue'
import { mapGetters } from 'vuex';
import VxCard from '../components/vx-card/VxCard.vue';
import PieChart from '../components-globalwork/PieChart.vue'
import ColumnChart from '../components-globalwork/ColumnChart.vue'

export default {
  mixins: [api,cities],
  data() {
    return {
      language: "es",
      languages: lang,
      totalCount: 0,
      startDateMask: null,
      endDateMask: null,
      startDate: null,
      endDate: null
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods:{
    async initializeData() {
      const request = await this.requestGetWithoutContent(`${this.url()}metrics/date-of-first-last-project?client_id=${this.userInfo.user.client_id}`, {})
      if (request.status == 200) {
        let data = request.data
        this.startDate = data.min.split('T')[0]
        this.endDate = data.max.split('T')[0]
        this.startDateMask = this.startDate
        this.endDateMask = this.endDate
      }
    },
    consultData(){
      eventBus.$emit('consultData')
      let params = `&start_date=${this.startDate}&end_date=${this.endDate}`
      let url = `${this.url()}metrics/total-projects?client_id=${this.userInfo.user.client_id}${params}`

      if(this.city_id != null) {
        console.log('No esta null')
        url += '&city_id='+this.city_id
      }
      this.getTotalProjects(url)
    },
    updateDateStartDate(field, data){
      if (typeof this.startDateMask !== 'string') {
        let dateString = this.startDateMask.getFullYear() + "-" + (this.startDateMask.getMonth() + 1) + "-" + this.startDateMask.getDate()
        this.startDate = dateString
      }
    },
    updateDateEndDate(field, data){
      if (typeof this.endDateMask !== 'string') {
        let dateString = this.endDateMask.getFullYear() + "-" + (this.endDateMask.getMonth() + 1) + "-" + this.endDateMask.getDate()
        this.endDate = dateString
      }
    },
    async getTotalProjects(url){
      const request = await this.requestGetWithoutContent(url, {})
      if (request.status == 200) {
        this.totalCount = request.data[0].TotalProjects
      }
    }
  },
  watch: {
    startDateMask() {
      this.updateDateStartDate('startDate', this.startDateMask)
    },
    endDateMask() {
      this.updateDateEndDate('endtDate', this.endDateMask)
    }
  },
  components: {
    Datepicker,
    vSelect,
    'bi-chart': BiChart,
    'candidate-years-experience': CandidateYearsExperience,
    'candidate-age-civil-state': CandidateAgeCivilState,
    'projects-by-results':ProjectsByResults,
    'candidate-age-study': CandidateAgeStudy,
    'candidate-civil-state-result': CandidateCivilStateResult,
    'qualification-average': QualificationAverage,
    'candidate-age-result': CandidateAgeResult,
    'projects-by-advance': ProjectsByAdvance,
    'candidate-civil-state-gender': CandidateCivilStateGender,
    'candidate-gender-age': CandidateGenderAge,
    'projects-by-type':ProjectsByType,
    'projects-by-charge':ProjectsByCharge,
    'banner-clients': BannerClients,
    VxCard,
    'untrust-projects': UntrustProjects,
    'unreliable-projects': UnreliableProjects,
    'pie-chart': PieChart,
    'column-chart': ColumnChart,

  },
  created(){
    if(this.userInfo == null ){
      window.localStorage.clear()
      this.$router.push('/pages/login')
    }
    this.initializeData()
    this.getCountries()
    this.getTotalProjects(`${this.url()}metrics/total-projects?client_id=${this.userInfo.user.client_id}`)
  },
}
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user{
    position: relative;
    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}
</style>
