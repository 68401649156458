var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pie-chart" },
    [
      _vm.url_report != ""
        ? _c("vx-card", { attrs: { title: "Reporte de clientes" } }, [
            _c("iframe", {
              attrs: {
                width: "100%",
                height: "1200",
                src: _vm.url_report,
                frameborder: "0",
                allowFullScreen: "true",
              },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }