var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "dashboard-analytics" } }, [
    _c("div", { staticClass: "vx-row" }, [
      _vm.startDate != null && _vm.endDate != null
        ? _c(
            "div",
            { staticClass: "vx-col w-full lg:w-1/1 mb-base" },
            [
              _c(
                "vx-card",
                { attrs: { title: "", id: "tabs-gráficas" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { "margin-bottom": "2rem" },
                      attrs: { id: "bannerContainer" },
                    },
                    [_c("banner-clients")],
                    1
                  ),
                  this.userInfo.role_name != "Cliente"
                    ? _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
                          [_c("pie-chart")],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
                          [_c("column-chart")],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "vx-col w-full lg:w-1/2 mb-base" }, [
                    _vm._v(
                      "\n          Total: " +
                        _vm._s(_vm.totalCount) +
                        " procesos\n          "
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-row" },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-col sm:w-1/4 w-full" },
                          [
                            _vm._v(
                              "\n              Fecha de inicio:\n              "
                            ),
                            _c("datepicker", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "pickadate-limits",
                              attrs: {
                                mode: "date",
                                language: _vm.languages[_vm.language],
                                name: "dateStart",
                              },
                              model: {
                                value: _vm.startDateMask,
                                callback: function ($$v) {
                                  _vm.startDateMask = $$v
                                },
                                expression: "startDateMask",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("dateStart"),
                                    expression: "errors.has('dateStart')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v("Requerido")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col sm:w-1/4 w-full" },
                          [
                            _vm._v(
                              "\n              Fecha final:\n              "
                            ),
                            _c("datepicker", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "pickadate-limits",
                              attrs: {
                                mode: "date",
                                language: _vm.languages[_vm.language],
                                name: "dateEnd",
                              },
                              model: {
                                value: _vm.endDateMask,
                                callback: function ($$v) {
                                  _vm.endDateMask = $$v
                                },
                                expression: "endDateMask",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("dateEnd"),
                                    expression: "errors.has('dateEnd')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v("Requerido")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "vs-select",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "vx-col sm:w-1/4 w-full",
                            attrs: {
                              id: "selectCountry",
                              autocomplete: true,
                              label: "País",
                              name: "country_id",
                            },
                            model: {
                              value: _vm.country_id,
                              callback: function ($$v) {
                                _vm.country_id = $$v
                              },
                              expression: "country_id",
                            },
                          },
                          _vm._l(_vm.countries, function (item, index) {
                            return _c("vs-select-item", {
                              key: index,
                              staticClass: "w-full",
                              attrs: { value: item.id, text: item.name },
                            })
                          }),
                          1
                        ),
                        _c(
                          "vs-select",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "vx-col sm:w-1/4 w-full",
                            attrs: {
                              id: "selectCity",
                              autocomplete: true,
                              label: "Ciudad",
                              name: "city_id",
                            },
                            model: {
                              value: _vm.city_id,
                              callback: function ($$v) {
                                _vm.city_id = $$v
                              },
                              expression: "city_id",
                            },
                          },
                          _vm._l(_vm.cities, function (item, index) {
                            return _c("vs-select-item", {
                              key: index,
                              staticClass: "w-full",
                              attrs: { value: item.id, text: item.name },
                            })
                          }),
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col sm:w-1/4 w-full" },
                          [
                            _c("br"),
                            _c(
                              "vs-button",
                              {
                                staticClass: "block",
                                attrs: { type: "filled" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.consultData.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("Consultar")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "vs-tabs",
                    [
                      _c("vs-tab", { attrs: { label: "Procesos" } }, [
                        _c("div", { staticClass: "con-tab-ejemplo" }, [
                          _c("div", { staticClass: "vx-row" }, [
                            _c(
                              "div",
                              { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
                              [
                                _c("projects-by-advance", {
                                  attrs: {
                                    startDate: _vm.startDate,
                                    endDate: _vm.endDate,
                                    cityId: _vm.city_id,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
                              [
                                _c("qualification-average", {
                                  attrs: {
                                    startDate: _vm.startDate,
                                    endDate: _vm.endDate,
                                    cityId: _vm.city_id,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "vx-row" }, [
                            _c(
                              "div",
                              { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
                              [
                                _c("projects-by-results", {
                                  attrs: {
                                    startDate: _vm.startDate,
                                    endDate: _vm.endDate,
                                    cityId: _vm.city_id,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
                              [
                                _c("projects-by-type", {
                                  attrs: {
                                    startDate: _vm.startDate,
                                    endDate: _vm.endDate,
                                    cityId: _vm.city_id,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
                              [
                                _c("untrust-projects", {
                                  attrs: {
                                    startDate: _vm.startDate,
                                    endDate: _vm.endDate,
                                    cityId: _vm.city_id,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
                              [
                                _c("unreliable-projects", {
                                  attrs: {
                                    startDate: _vm.startDate,
                                    endDate: _vm.endDate,
                                    cityId: _vm.city_id,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _c("vs-tab", { attrs: { label: "Candidatos" } }, [
                        _c("div", { staticClass: "con-tab-ejemplo" }, [
                          _c("div", { staticClass: "vx-row" }, [
                            _c(
                              "div",
                              { staticClass: "vx-col lg:w-1/2 mb-base" },
                              [
                                _c("candidate-gender-age", {
                                  attrs: {
                                    startDate: _vm.startDate,
                                    endDate: _vm.endDate,
                                    cityId: _vm.city_id,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "vx-col lg:w-1/2 mb-base" },
                              [
                                _c("candidate-civil-state-gender", {
                                  attrs: {
                                    startDate: _vm.startDate,
                                    endDate: _vm.endDate,
                                    cityId: _vm.city_id,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "vx-row" }, [
                            _c(
                              "div",
                              { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
                              [
                                _c("candidate-age-result", {
                                  attrs: {
                                    startDate: _vm.startDate,
                                    endDate: _vm.endDate,
                                    cityId: _vm.city_id,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
                              [
                                _c("candidate-age-civil-state", {
                                  attrs: {
                                    startDate: _vm.startDate,
                                    endDate: _vm.endDate,
                                    cityId: _vm.city_id,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "vx-row" }, [
                            _c(
                              "div",
                              { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
                              [
                                _c("candidate-civil-state-result", {
                                  attrs: {
                                    startDate: _vm.startDate,
                                    endDate: _vm.endDate,
                                    cityId: _vm.city_id,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "vx-col w-full lg:w-1/2 mb-base" },
                              [
                                _c("candidate-age-study", {
                                  attrs: {
                                    startDate: _vm.startDate,
                                    endDate: _vm.endDate,
                                    cityId: _vm.city_id,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("div", [_vm._v("\n      No existe información\n    ")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }