<template>
  <div class="pie-chart">
    <vx-card title="Reporte de clientes" v-if="url_report != ''">
      <iframe width="100%" height="1200" :src="url_report" frameborder="0" allowFullScreen="true"></iframe>
    </vx-card>    
  </div>
</template>
<script>
export default {
  data(){
    return{
      clientId: null,
      url_report: ''
    }
  },
  methods:{

  },
  created: function(){
    this.url_report = localStorage.getItem('url_report')
  },    
  watch: {

  },  
  components: {

  }
}
</script>
<style>

</style>
