<template>
  <div id="qualification_average_by_project_type">
    <vx-card title="Promedio de calificación por tipo">
      <apexcharts type="bar" height="350" :options="chartOptions" :series="series" v-if="renderGraphic"></apexcharts>
    </vx-card>
  </div>

</template>

<script>
import { eventBus } from './../../main'
import VueApexCharts from 'vue-apexcharts';
import { mapGetters } from 'vuex';
import api from './../../mixins/api'
export default {
  props: ['startDate', 'endDate', 'cityId'],
  mixins:[api],
  data(){
    return{
      renderGraphic:false,
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        colors: ['#5F0091'],
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Promedio de calificación por tipo',
          },
        },
      },
      series: [{
        name: 'Promedio',
        data: [5, 4.29, 5, 3.30, 4.25, 2.1]
      }],
    }
  },
  components:{
    'apexcharts':VueApexCharts,
  },
  methods:{
    getUrl(){
      let path = `/metrics/qualification-average?client_id=${this.userInfo.user.client_id}`
      let url = ''
      if (this.startDate != null && this.endDate != null) {
        let params = `&start_date=${this.startDate}&end_date=${this.endDate}`
        url = `${this.url()}${path}${params}`
      } else {
        url = `${this.url()}${path}`
      }

      if(this.cityId != null) {
        url += '&city_id='+this.cityId
      }
      return url
    },
    async initializeData(){
      const request = await this.requestGetWithoutContent(this.getUrl(), {})
      if(request.status == 200){
        console.log(request)
        this.updateXaxis(request.data)
        this.updateSeries(request.data)
      }
    },
    async updateXaxis(data) {
      let categories = []
      data.forEach(element => categories.push(this.$t(element.name)))
      this.$set(this.chartOptions, 'xaxis', { categories: categories })
    },
    async updateSeries(data) {
      let series = []
      data.forEach(element => series.push(element.round))
      this.$set(this.series, 0, { data: series })
      this.renderGraphic = true
    },
  },
  mounted(){
    this.initializeData()
    eventBus.$on('consultData', () => {
      this.initializeData()
    })
  },
  computed:{
    ...mapGetters(['userInfo'])
  }
}
</script>

<style>

</style>