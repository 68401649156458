<template>
  <div class="pie-chart">
    <vx-card title="Status Procesos Terminados">
      Total: {{totalCount}} procesos
      <form class="form-graphic">
        <div class="vx-row">
          <div class="vx-col sm:w-1/4 w-full">
            Fecha de inicio:
            <datepicker :language="languages[language]" v-validate="'required'" class="pickadate-limits" name="dateStart" v-model="form.dateStart"></datepicker>
            <span class="text-danger text-sm" v-show="errors.has('dateStart')">Requerido</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            Fecha final:
            <datepicker :language="languages[language]" v-validate="'required'" class="pickadate-limits" name="dateEnd" v-model="form.dateEnd"></datepicker>
            <span class="text-danger text-sm" v-show="errors.has('dateEnd')">Requerido</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            Ciudades: <v-select v-model="form.city_id" :options="cities" name="project.city_id" ></v-select>
            <span class="text-danger text-sm" v-show="errors.has('city')">Requerido</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <br>
            <vs-button type="filled" @click.prevent="submitForm" class="block">Consultar</vs-button>
          </div>
        </div>
        <br>
      </form>
      <br>
      <vue-apex-charts type="pie" height="350" :options="pieChart.chartOptions" :series="pieChart.series"></vue-apex-charts>
    </vx-card>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import vSelect from 'vue-select'
import api from '../mixins/api'
import Datepicker from 'vuejs-datepicker';
import * as lang from 'vuejs-datepicker/src/locale';
import { mapGetters } from 'vuex'
export default {
  mixins: [api],
  data(){
    return{
      language: "es",
      languages: lang,
      form: {
      },
      themeColors: ['success', 'warning', 'dangerous'],
      totalCount: 0,
      pieChart: {
        series: [0, 0, 0],
        chartOptions: {
          labels: ['Confiables', 'No confiables', 'Confiables con observaciones'],
          colors: ['#28C76F', '#EA5455', '#FF9F43'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        }
      },
      startDate: '',
      endDate: '',
      periods: this.createPeriods(),
      period: this.createPeriods()[0]
    }
  },
  methods:{
    submitForm() {
      this.$validator.validateAll().then(result => {
        if(result) {
          var user_id = this.userInfo.user.id
          var start_date = this.form.dateStart;
          var end_date = this.form.dateEnd;
          var parameters = "?start_date=" + start_date + "&end_date=" + end_date + "&current_user=" + user_id
          if(this.form.city_id != null){
            parameters += "&city_id=" + this.form.city_id.id
          }
          var path = "/api/v1/reports/clients_pie_chart" + parameters
          this.getData(path)
        }else{
          this.$vs.notify({color:'danger',title:'Error',text:'Todos los campos son necesarios', position: 'bottom-left'})
        }
      })
    },
    async getData(path){
      let response = await this.requestGetWithoutContent(this.url()+path, {})
      if(response.data.success == true){
        this.pieChart.series = response.data.series
        this.totalCount = response.data.total_count
        this.startDate = response.data.start_date
        this.endDate = response.data.end_date
        this.form.dateStart = response.data.start_date
        // this.form.dateEnd = response.data.end_date // change for pm. form.dateEnd is current date
        }
    },
    convertDate(date){
      var parts = date.split("-");
      var months = {Jan: "01",Feb: "02",Mar: "03",Apr: "04",May: "05",Jun: "06",Jul: "07",Aug: "08",Sep: "09",Oct: "10",Nov: "11",Dec: "12"};
      return parts[0]+"-"+months[parts[1]]+"-"+parts[2];
    },
    createPeriods(){
      var years = ['2018', '2019']
      var months = [
        {name: 'Enero',date: '01'},
        {name: 'Febrero',date: '02'},
        {name: 'Marzo',date: '03'},
        {name: 'Abril',date: '04'},
        {name: 'Mayo',date: '05'},
        {name: 'Junio',date: '06'},
        {name: 'Julio',date: '07'},
        {name: 'Agosto',date: '08'},
        {name: 'Septiembre',date: '09'},
        {name: 'Octubre',date: '10'},
        {name: 'Noviembre',date: '11'},
        {name: 'Diciembre',date: '12'}
      ]
      var periods = []
      var cont = 1
      var currentDate = new Date();
      for (let i = 0; i < years.length; i++) {
        for (let j = 0; j < months.length; j++) {
          var value = years[i] + "-" + months[j].date + "-" + "01";
          if(Date.parse(value) <= currentDate){
            var text = months[j].name + " " + years[i]
            var id = cont
            var period = {id: id, text: text, value: value};
            periods.push(period);
            cont += 1;
          }
        }
      }
      periods = periods.sort(function(a, b){ return b.id-a.id});
      return periods;
    },
    lastPeriod(){
      //console.log('lastPeriod');
      //console.log(JSON.stringify(this.createPeriods()[0]));
      this.period = [this.createPeriods()[0]];
    }
  },
  created: function(){
    var user_id = this.userInfo.user.id
    var parameters = "?current_user=" + user_id
    var path = "/api/v1/reports/clients_pie_chart" + parameters
    var cities = this.userInfo.user.cities.cities
    var cities_select = []
    cities.forEach(city => {cities_select.push({id: city.id, label: city.name})});
    this.cities = cities_select;
    this.getData(path)
  },
  watch: {
    period: function(){
      this.pieChart.series = [33, 33, 33] // propertie pieChart update with date response api
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  components: {
    VueApexCharts,
    Datepicker,
    vSelect
  },
  mounted () {
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    this.form.dateEnd = date;
  },
}
</script>
<style>
  .vdp-datepicker input{
    width: 100%
  }
</style>
<style scoped>
  .form-graphic{
    margin-top: 1rem;
  }
</style>
