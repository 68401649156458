var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    { attrs: { title: "Procesos por resultados", id: "card" } },
    [
      _vm.renderGraphic
        ? _c("VueApexCharts", {
            attrs: {
              type: "donut",
              height: "350",
              options: _vm.chartOptions,
              series: _vm.series,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }