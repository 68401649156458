<template>
  <div id="candidate-years-experience">
    <vx-card title="Procesos por avance">
      <apexcharts type="bar" height="350" :options="chartOptions" :series="series" v-if="renderGraphic"></apexcharts>
    </vx-card>
  </div>
</template>

<script>
import { eventBus } from './../../main'
import VueApexCharts from 'vue-apexcharts';
import api from '../../mixins/api'
import { mapGetters } from 'vuex'
export default {
  props: ['startDate', 'endDate','cityId'],
  mixins:[api],
  data(){
    return{
      renderGraphic:false,
      series: [{
        name:'% de Procesos',
            data: ['98%', '22%', '10%']
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
            dataLabels: {
                  position: 'top', // top, center, bottom
                },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        legend: {
          show: false
        },
        colors: ['#5F0091'],
        xaxis: {
          categories: [
            ['Finalizados'],
            ['En Progreso'],
            ['Preliminar']
          ],
          labels: {
            style: {
              fontSize: '12px'
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value + "%";
            }
          },
        },
      },
    }
  },
  components: {
    'apexcharts':VueApexCharts,
  },
  methods:{
    getUrl(){
      let path = `/metrics/projects-by-advance?client_id=${this.userInfo.user.client_id}`
      let url = ''
      if (this.startDate != null && this.endDate != null) {
        let params = `&start_date=${this.startDate}&end_date=${this.endDate}`
        url = `${this.url()}${path}${params}`
      } else {
        url = `${this.url()}${path}`
      }

      if(this.cityId != null) {
        url += '&city_id='+this.cityId
      }
      return url
    },
    async initializeData(){
      const request = await this.requestGetWithoutContent(this.getUrl(), {})
      if (request.status == 200) {
        let data = request.data
        await this.updateSeries(data)
      }
    },
    async updateSeries(data) {
      let series = []
      let preValues = Object.values(data[0])
      for(let i = 1; i<=3; i++){
        series.push((preValues[i] * (100/preValues[0])).toFixed(2))
      }
      this.$set(this.series, 0, { name:'% de Procesos', data: series })
      this.renderGraphic = true
    },
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted () {
    this.initializeData()
    eventBus.$on('consultData', () => {
      this.initializeData()
    })
  },
}
</script>

<style>

</style>