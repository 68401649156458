<template>
  <div>
    
    <vx-card title="Reporte Histórico de Procesos">      
      Total: {{totalCount}} procesos
      <form class="form-graphic">
        <div class="vx-row">
          <div class="vx-col sm:w-1/4 w-full">
            Fecha de inicio:
            <datepicker :language="languages[language]" v-validate="'required'" class="pickadate-limits" name="dateStart" v-model="form.dateStart"></datepicker>
            <span class="text-danger text-sm" v-show="errors.has('dateStart')">Requerido</span>            
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            Fecha final:
            <datepicker :language="languages[language]"  v-validate="'required'" class="pickadate-limits" name="dateEnd" v-model="form.dateEnd"></datepicker>
            <span class="text-danger text-sm" v-show="errors.has('dateEnd')">Requerido</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            Ciudades: <v-select v-model="form.city_id" :options="cities" name="project.city_id" ></v-select>              
            <span class="text-danger text-sm" v-show="errors.has('city')">Requerido</span>
          </div>          
          <div class="vx-col sm:w-1/4 w-full">
            <br>
            <vs-button type="filled" @click.prevent="submitForm" class="block">Consultar</vs-button>
          </div>                    
        </div>
      </form>           
      <vue-apex-charts id="chart" type="bar" height="350" ref="chart" v-bind:options="columnChart.chartOptions" v-bind:series="series" v-if="this.columnChart.chartOptions.xaxis.categories.length > 0 && series.length > 0"></vue-apex-charts>
      <!-- <div id="chart" ref="chart"></div> -->
    </vx-card>
  </div>

</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import api from '../mixins/api'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import * as lang from 'vuejs-datepicker/src/locale';
import { mapGetters } from 'vuex'
export default {
  mixins: [api],
  data(){
    return {
      language: "es",
      languages: lang,      
      totalCount: 0,
      cities: [],
      nameMonths: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      form: {

      }, 
      // series: [],
      columnChart: {
        chart:{
          id: 'chart'
        },
        series: [{
            name: 'Número de procesos',
            data: []
          }],
        chartOptions: {
          colors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: 'rounded',
              columnWidth: '55%',
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: []
          },
          yaxis: {
            title: {
              text: 'Número de procesos'
            }
          },
          fill: {
            opacity: 1

          },
          tooltip: {
            y: {
              formatter: function(val) {
                return val + " procesos realizados"
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods:{
    submitForm() {
      this.$validator.validateAll().then(result => {        
        if(result) {
          this.initializeData();
        }else{
          this.$vs.notify({color:'danger',title:'Error',text:'Todos los campos son necesarios', position: 'bottom-left'})      
        }
      })
    },
    initializeData(){
      var user_id = this.userInfo.user.id    
      var parameters = "?current_user=" + user_id
      if(this.form.dateStart != null && this.form.dateEnd != null){
        parameters += "&start_date=" + this.form.dateStart.toISOString().substr(0,10) + "&end_date=" + this.form.dateEnd
      }
      if(this.form.city_id != null){
        parameters += "&city_id=" + this.form.city_id.id
      }
      var path = "/api/v1/reports/processes_per_month_city" + parameters
      this.getData(this.url()+path)
    },
    async getData(path){
      let response = await this.requestGetWithoutContent(path,{})
        if(response.data.success == true){
          var data = response.data          
          this.totalCount = data.total_count
          var months = []
          data.months.forEach(date => {
            months.push(date)
          });
          this.columnChart = {
            chart:{
              id: 'chart'
            },
            series: [{
                name: 'Número de procesos',
                data: []
              }],
            chartOptions: {
              colors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
              plotOptions: {
                bar: {
                  horizontal: false,
                  endingShape: 'rounded',
                  columnWidth: '55%',
                },
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
              },
              xaxis: {
                categories: months
              },
              yaxis: {
                title: {
                  text: 'Número de procesos'
                }
              },
              fill: {
                opacity: 1
              },
              tooltip: {
                y: {
                  formatter: function(val) {
                    return val + " procesos realizados"
                  }
                }
              }
            }
          } 
          this.series = data.series 
          this.form.dateStart = data.start_date       
          this.form.dateEnd = data.end_date
        }else{
          //console.log("fail")
        }          
    },
    convertDate(date){
      //console.log("d", date)
      var parts = date.split(" ");
      var months = {Jan: "01",Feb: "02",Mar: "03",Apr: "04",May: "05",Jun: "06",Jul: "07",Aug: "08",Sep: "09",Oct: "10",Nov: "11",Dec: "12"};
      return parts[3]+"-"+months[parts[1]]+"-"+parts[2];
    }
  },
  created: function(){
    var cities = this.userInfo.user.cities.cities 
    var cities_select = []
    cities.forEach(city => {cities_select.push({id: city.id, label: city.name})});
    this.cities = cities_select;
    this.initializeData()
  },     
  components: {
    VueApexCharts,
    vSelect,
    Datepicker
  }
}
</script>
<style scoped>
  .form-graphic{
    margin-top: 1rem;
  }
</style>
