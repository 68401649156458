<template>
  <div id="candidate-years-experience">
    <vx-card title="Candidatos por genero y edad">
      <VueApexCharts type="bar" height="350" :options="chartOptions" :series="series" v-if="renderGraphic"/>
    </vx-card>
  </div>
</template>

<script>
import { eventBus } from './../../main'
import VueApexCharts from 'vue-apexcharts';
import api from '../../mixins/api'
import { mapGetters } from 'vuex'
export default {
  props: ['startDate', 'endDate', 'cityId'],
  mixins: [api],
  data() {
    return {
      renderGraphic: false,
      series: [{}, {}],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          stackType: '100%'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        colors: ['#5F0091', '#FFCF44'],
        xaxis: {
          categories: ['14-20', '21-25', '26-30', '31-35', '36-40', '41-50', '46-50'],
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'right',
          offsetX: 0,
          offsetY: 50
        },
      },
    }
  },
  methods: {
    getUrl(){
      let path = `/metrics/candidate-gender-age?client_id=${this.userInfo.user.client_id}`
      let url = ''
      if (this.startDate != null && this.endDate != null) {
        let params = `&start_date=${this.startDate}&end_date=${this.endDate}`
        url = `${this.url()}${path}${params}`
      } else {
        url = `${this.url()}${path}`
      }
      if(this.cityId != null) {
        url += '&city_id='+this.cityId
      }
      return url
    },
    async initializeData() {
      const request = await this.requestGetWithoutContent(this.getUrl(), {})
      if (request.status == 200) {
        let data = request.data
        await this.updateSeries(data)
      }
    },
    async updateSeries(data) {
      for (let index = 0; index < 2; index++) {
        this.$set(this.series, index, { name: data.genders[index], data: data.series[index] })
      }
      this.renderGraphic = true
    },
  },
  mounted () {
    this.initializeData()
    eventBus.$on('consultData', () => {
      this.initializeData()
    })
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  components: {
    VueApexCharts,
  }
}
</script>
<style scoped>
  .form-graphic{
    margin-top: 1rem;
  }
</style>
