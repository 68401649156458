var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "candidate-years-experience" } },
    [
      _c(
        "vx-card",
        { attrs: { title: "Candidatos por edad y estudios" } },
        [
          _c("VueApexCharts", {
            attrs: {
              type: "area",
              height: "350",
              options: _vm.chartOptions,
              series: _vm.series,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }