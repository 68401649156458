<template>
  <vx-card title="Procesos por cargo" id="card">
    <vue-apex-charts type="bar" height="1000" :options="chartOptions" :series="series" v-if="renderGraphic" ref="projectsByCharges"></vue-apex-charts>
    <div style="display: flex; justify-content: center;">
      <button @click="prevPage" style="background: transparent; border: none; cursor:pointer;">
        <span class="material-icons" >arrow_back_ios</span>
      </button>
        {{this.position + 1 }}/{{pages.length}}
      <button @click="nextPage" style="background: transparent; border: none; cursor:pointer;">
        <span class="material-icons">arrow_forward_ios</span>
      </button>
    </div>
  </vx-card>
</template>

<script>
import { eventBus } from './../../main'
import VueApexCharts from 'vue-apexcharts';
import api from '../../mixins/api'
import { mapGetters } from 'vuex'
export default {
  props: ['startDate', 'endDate', 'cityId'],
  mixins:[api],
  data(){
    return{
      renderGraphic: false,
      pages:[],
      position:0,
      series: [
        {
          name: 'Procesos por cargo',
          data:[]
        }
      ],
      chartOptions:{
        legend: {
          show:true
        },
        height: '1000',
        colors: ['#5F0091'],
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -5,
          style: {
            fontSize: '16px',
            colors: ["#ffffff"]
          }
        }
      },

    }
  },
  components: {
    VueApexCharts,
  },
  methods:{
    getUrl(){
      let path = `/metrics/projects-by-charge?client_id=${this.userInfo.user.client_id}`
      let url = ''
      if (this.startDate != null && this.endDate != null) {
        let params = `&start_date=${this.startDate}&end_date=${this.endDate}`
        url = `${this.url()}${path}${params}`
      } else {
        url = `${this.url()}${path}`
      }

      if(this.cityId != null) {
        url += '&city_id='+this.cityId
      }

      return url
    },
    async initializeData() {
      const request = await this.requestGetWithoutContent(this.getUrl(), {})
      if (request.status == 200) {
        let data = request.data
        await this.updateSeries(data)
      }
    },
    updateSeries(data){
      this.pages = this.chunkArray(data,15)
      let titles  = this.pages[this.position].map(element => element.current_activity)
      this.$set(this.chartOptions, 'xaxis' , {categories:titles})
      this.pages[0].forEach((element,index) => {this.$set(this.series[0].data, index, element.count)})
      this.renderGraphic = true
    },
    chunkArray(myArray, chunk_size){
      let index = 0;
      let arrayLength = myArray.length;
      let tempArray = [];

      for (index = 0; index < arrayLength; index += chunk_size) {
        let myChunk = myArray.slice(index, index+chunk_size);
        tempArray.push(myChunk);
      }
      return tempArray;
    },
    nextPage(){
      if(this.position < this.pages.length - 1){
        this.position += 1
        this.renderGraphic = false
        let pages = this.pages[this.position].map(element => element.count)
        let titles  = this.pages[this.position].map(element => element.current_activity)
        this.$refs.projectsByCharges.updateOptions({xaxis:{categories:titles}},false)
        this.$set(this.series, 0, {data:pages})
        this.renderGraphic = true
        
      }
    },
    prevPage(){
      if(this.position > 0){
        this.position -= 1
        let pages = this.pages[this.position].map(element => element.count)
        let titles  = this.pages[this.position].map(element => element.current_activity)
        this.$refs.projectsByCharges.updateOptions({xaxis:{categories:titles}},false)
        this.$set(this.series, 0, {data:pages})
        
      }
    }
  },
  computed:{
    ...mapGetters(['userInfo'])
  },
  mounted(){
    this.initializeData()
    eventBus.$on('consultData', () => {
      this.initializeData()
    })
  }
}
</script>

<style>

</style>