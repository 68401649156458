<template>
  <div class="banner">
    <p style="padding-top: 92px; font-size: 34px; padding-left: 40px;">{{client_name}}</p>
  </div>
</template>

<script>
import api from '../../mixins/api'
import { mapGetters } from 'vuex'
export default {
  mixins:[api],
  data(){
    return{
      client_name:''
    }
  },
  methods:{
    getUrl(){
      let path = `metrics/client?client_id=${this.userInfo.user.client_id}`
      let url = `${this.url()}${path}`
      return url
    },
    async initializeData() {
      const request = await this.requestGetWithoutContent(this.getUrl(), {})
      if (request.status == 200) {
        let data = request.data
        this.client_name = data[0].name
      }
    }
  },
  mounted(){
    this.initializeData()
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
}
</script>

<style scoped>
  .banner{
    background-image: url('https://res.cloudinary.com/dhcti0lrk/image/upload/v1608667711/globalwork/2020-12-22_11-09_mvvpiw.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 186px;
  }
</style>