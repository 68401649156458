import { render, staticRenderFns } from "./candidate_gender_age.vue?vue&type=template&id=a61b1e34&scoped=true&"
import script from "./candidate_gender_age.vue?vue&type=script&lang=js&"
export * from "./candidate_gender_age.vue?vue&type=script&lang=js&"
import style0 from "./candidate_gender_age.vue?vue&type=style&index=0&id=a61b1e34&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a61b1e34",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a61b1e34')) {
      api.createRecord('a61b1e34', component.options)
    } else {
      api.reload('a61b1e34', component.options)
    }
    module.hot.accept("./candidate_gender_age.vue?vue&type=template&id=a61b1e34&scoped=true&", function () {
      api.rerender('a61b1e34', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components-globalwork/metrics/candidate_gender_age.vue"
export default component.exports