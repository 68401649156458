<template>
  <div id="candidate-years-experience">
    <vx-card title="Candidatos por edad y estudios">
      <VueApexCharts type="area" height="350" :options="chartOptions" :series="series"/>
    </vx-card>
  </div>
</template>

<script>
import { eventBus } from './../../main'
import VueApexCharts from 'vue-apexcharts';
import api from '../../mixins/api'
import { mapGetters } from 'vuex'
export default {
  props: ['startDate', 'endDate', 'cityId'],
  mixins: [api],
  data() {
    return {
      renderGraphic: false,
      series: [{
        name: 'Bachillerato',
        type: 'area',
        data: []
      }, {
        name: 'Formación superior',
        type: 'line',
        data: []
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
        },
        stroke: {
          curve: 'smooth'
        },
        fill: {
          type:'solid',
          opacity: [0.35, 1],
        },
        labels: ['18-20', '21-25', '26-30', '31-35', '36-40', '41-45', '46-50'],
        markers: {
          size: 0
        },
        colors: ['#5F0091', '#FFCF44'],
        yaxis: [
          {
            title: {
              text: 'Candidatos'
            },
          },
          {
            opposite: true,
            title: {
              text: 'Candidatos',
            },
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if(typeof y !== "undefined") {
                return  y.toFixed(0) + " candidatos";
              }
              return y;
            }
          }
        }
      },
    }
  },
  methods: {
    getUrl(){
      let path = `metrics/candidate-age-study?client_id=${this.userInfo.user.client_id}`
      let url = ''
      if (this.startDate != null && this.endDate != null) {
        let params = `&start_date=${this.startDate}&end_date=${this.endDate}`
        url = `${this.url()}${path}${params}`
      } else {
        url = `${this.url()}${path}`
      }

      if(this.cityId != null) {
        url += `&city_id=${this.cityId}`
      }
      return url
    },
    async initializeData() {
      const request = await this.requestGetWithoutContent(this.getUrl(), {})
      if (request.status == 200) {
        let data = request.data
        await this.updateSeries(data)
      }
    },
    async updateSeries(data) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        let key = Object.keys(element)
        let series = []
        let name = 'Bachillerato'
        element[key].forEach(element => {
          series.push(element[Object.keys(element)[0]])
        });
        if (index === 1) {
          name = 'Formación superior'
        }
        this.$set(this.series, index, { name: name, type: 'area', data: series })
      }
      this.renderGraphic = true
    },
  },
  mounted () {
    this.initializeData()
    eventBus.$on('consultData', () => {
      this.initializeData()
    })
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  components: {
    VueApexCharts,
  }
}
</script>
<style scoped>
  .form-graphic{
    margin-top: 1rem;
  }
</style>
